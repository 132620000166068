import { configureStore } from "@reduxjs/toolkit";
import testAppSliceReducer from "./testAppSlice"
import authSliceReducer from "./authSlice"
import loadingModalSlice from "./loadingModalSlice";

export const store = configureStore({
    reducer: {
        testApp: testAppSliceReducer,
        auth: authSliceReducer,
        loadingState: loadingModalSlice
    },
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         serializableCheck: {
    //             ignoredActions: ["auth/setUser"]
    //         }
    //     })
})