import "./UserPage.css"
import { Topic } from "../../components/Topic"
import Row from "react-bootstrap/Row";
import { sampleTopics } from "../../constants";

export default function UserPage() {
    let listOfTopic = sampleTopics

    const topicList = listOfTopic.map((topic, idx) => {
        return <Topic topic={topic} key={idx} />
    })

    return (
        <div id="user-page">
            <Row id="topics">
                {
                    topicList
                }
            </Row>

        </div >
    )
}