// Starting page of test
import "./StartTest.css"
import { useSelector, useDispatch } from "react-redux"
import { getQuestionsForTest } from "../../redux/testAppSlice"

export default function StartTest() {

    const dispatch = useDispatch()
    const topic = useSelector((state) => state.testApp.topic)
    const accessToken = useSelector((state) => state.auth.token)
    const uid = useSelector((state) => state.auth.uid)

    return (
        <div id="start-test-page">
            <h1 className="text-center">Are you ready to do test?</h1>
            <h3 className="text-center">{topic}</h3>
            <h6 className="text-center">Instructions</h6>
            <p className="text-center">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
            <div className="text-center" >
                <button onClick={() => dispatch(getQuestionsForTest({ accessToken, topic, uid }))} >Start Test</button>
            </div>
        </div>
    )
}