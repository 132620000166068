import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setAnswerByQID } from '../../redux/testAppSlice';
export default function QuestionTypeOne({ qns, idx }) {
    const dispatch = useDispatch()
    const options = qns.options.map((opt, idx) => <Form.Check key={idx} type='radio' name={qns.qid} label={opt} value={opt} />)

    return (
        <div style={{
            border: '1px solid black',
            padding: '5px'
        }}>
            {idx + 1 + ". "}
            {
                qns.prompt
            }
            <br />
            <Form onChange={(e) => dispatch(setAnswerByQID({ qid: qns.qid, value: e.target.value }))}>
                {
                    options
                }
            </Form>

        </div>
    )
}