import './App.css';
import LandingPage from "./pages/landing-page/LandingPage"
import ErrorPage from "./pages/error-page/ErrorPage"
import UserPage from "./pages/user-page/UserPage"
import SignupPage from "./pages/signup-page/SignupPage"
import TestApp from './pages/test-app/TestApp';
import Navbar from "./components/Navbar"
import { Routes, Route } from "react-router-dom"
import Footer from './components/Footer';
import { ProtectedRoutes } from "./services/ProtectedRoutes"
import { LoadingModal } from './components/LoadingModal';
import React from 'react';

function App() {
  return (
    <React.Fragment>
      <LoadingModal />
      <div id="main">
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/signup" element={<SignupPage />}></Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/user" element={<UserPage />}></Route>
            <Route path="/test" element={<TestApp />}></Route>
          </Route>
          <Route path="*" element={<ErrorPage />}></Route>
        </Routes>
        <Footer />
      </div>
    </React.Fragment>

  );
}

export default App;
