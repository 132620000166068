import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { HTTP_STATUS } from "../constants";
import { gradeTest } from "../services/utilityFunctions";
import { getQuestionsByTopic, getAnswersForTest } from "../services/apiServices";

const initialState = {
    tsid: "",
    phase: 0,
    topic: "",
    questions: [],
    questionsLoadStatus: '',
    testResponse: {},
    crashStatus: false
}

// Async thunks
export const getQuestionsForTest = createAsyncThunk('testApp/getQuestionsByTopic', getQuestionsByTopic)
export const gradeQuestionsForTest = createAsyncThunk('testApp/gradeQuestionsByTopic', getAnswersForTest)

const testAppSlice = createSlice({
    name: "testApp",
    initialState,
    reducers: {
        startTest: (state) => {
            if (state.questionsLoadStatus === HTTP_STATUS.FULFILLED) {
                state.phase = 1
            }
        },
        submitTest: (state) => {
            console.log("submittest method called")
            state.phase = 2
        },
        setTopic: (state, action) => {
            state.topic = action.payload.toUpperCase()
        },
        restartTest: (state) => {
            state.phase = 0
        },
        resetState: () => {
            return initialState
        },
        getQuestions: (state, action) => {
            console.log(action.payload)
        },
        setAnswerByQID: (state, action) => {
            state.testResponse[action.payload.qid] = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionsForTest.pending, (state) => {
                state.questionsLoadStatus = HTTP_STATUS.PENDING
            })
            .addCase(getQuestionsForTest.fulfilled, (state, action) => {
                state.questionsLoadStatus = HTTP_STATUS.FULFILLED
                let testQuestions = action.payload.testQuestions
                state.questions = testQuestions
                if (testQuestions.length > 0) {
                    state.tsid = action.payload.tsid
                    state.phase = 1
                } else {
                    alert("No questions found")
                }

            })
            .addCase(getQuestionsForTest.rejected, (state) => {
                state.questionsLoadStatus = HTTP_STATUS.REJECTED
                alert("error")
                console.log('questions is rejected')
            })

        // add the handling of grading questions here
        builder
            .addCase(gradeQuestionsForTest.pending, (state) => {
                console.log("getting answers for the questions")
            })
            .addCase(gradeQuestionsForTest.fulfilled, (state, action) => {
                console.log("answers received")
                // call the grading of questions here
                let { gradedQuestions, count } = gradeTest(state.questions, state.testResponse, action.payload.answers)
                state.questions = gradedQuestions
                state.numberOfCorrectQuestions = count
                console.log(current(state))
                state.phase = 2
                console.log("questions are graded successfully")
            })
            .addCase(gradeQuestionsForTest.rejected, (state) => {
                console.log("unable to get the answers for the questions")
            })
    }
})

export const { startTest, submitTest, restartTest, setTopic, resetState, setAnswerByQID } = testAppSlice.actions
export default testAppSlice.reducer