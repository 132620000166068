import Container from 'react-bootstrap/Container';
import { Navbar, Nav } from 'react-bootstrap';
import { auth } from '../services/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetState } from "../redux/testAppSlice"
// import { useEffect } from 'react';



function NavBar() {

    const loggedIn = useSelector((state) => state.auth.isLoggedIn)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const logout = () => {
        auth.signOut().then(() => {
            console.log("Signed out successfully")
        })
            .catch((err) => {
                console.error("Error signing out: ", err)
            })
    }

    const toHomePage = () => {
        dispatch(resetState())
        navigate("/")
    }

    return (
        <>
            <Navbar bg="dark" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand onClick={toHomePage}>Navbar</Navbar.Brand>
                    <Nav className="ml-auto">
                        <Nav.Link onClick={toHomePage}>Home</Nav.Link>
                        {
                            loggedIn
                                ? <Nav.Link onClick={() => logout()}>Logout</Nav.Link> : <Nav.Link onClick={() => navigate("/signup")}>Sign Up/Login</Nav.Link>
                        }
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}

export default NavBar;