import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    token: "",
    uid: ""
}

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        loggedIn: (state) => {
            state.isLoggedIn = true
        },
        loggedOut: (state) => {
            state.isLoggedIn = false
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setUID: (state, action) => {
            state.uid = action.payload
        }
    }
})

export const { loggedIn, loggedOut, setToken, setUID } = authSlice.actions
export default authSlice.reducer