import { QUESTION_TYPE } from "../constants"
import QuestionTypeOne from "../components/questions/QuestionTypeOne"
import QuestionTypeOneGraded from "../components/questions/QuestionTypeOneGraded"

export function gradeTest(test, testResponse, testAnswers) {
    // check what kind of questions and grade accordingly
    let count = 0
    for (let i = 0; i < test.length; i++) {
        const question = test[i]
        const qid = question.qid
        const userResponse = testResponse[qid]
        const correctAnswer = testAnswers[qid]
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            const result = gradeQuestionTypeOne(userResponse, correctAnswer)
            if (result) count++
            question['grade'] = result
        }
    }
    return { gradedQuestions: test, count }
}

export const renderQuestions = (questions) => {
    return questions.map((question, idx) => {
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            return <QuestionTypeOne key={idx} qns={question} idx={idx} />
        }
        return null
    })
}

export const renderGradedQuestions = (questions) => {
    return questions.map((question, idx) => {
        if (question.qnTyp === QUESTION_TYPE.MCQ) {
            return <QuestionTypeOneGraded key={idx} qns={question} idx={idx} />
        }
        return null
    })
}

function gradeQuestionTypeOne(response, answer) {
    if (response === answer[0]) return true
    else return false
}




// sample test data
// test is an array of questions
// can get from redux
// let test = [
//     {
//         qid: '1',
//         qnTyp: 'mcq',
//         prompt: 'Irreversible COX inhibitor',
//         options: ['Aspirin', 'Naproxen', 'Diclofenac', 'Celecoxib']
//     },
//     {
//         qid: '2',
//         qnTyp: 'mcq',
//         prompt: 'NSAIDs are useful as analgesic for: ',
//         options: ['Moderate to severe pain', 'Neuropathic pain', 'Severe pain', 'Mild to moderate pain']
//     },
//     {
//         qid: '3',
//         qnTyp: 'mcq',
//         prompt: 'An elderly patient presents with bruising after taking an analgesic. The drug was most likely',
//         options: ['Aspirin', 'Naproxen', 'Codeine', 'Ibuprofen']
//     }
// ]

// let testAnswers = {
//     1: 'Aspirin',
//     2: 'Mild to moderate pain',
//     3: 'Aspirin'
// }

// let testResponse = {
//     1: 'Aspirin',
//     2: 'Mild to modera',
//     3: 'Aspirin'
// }
// gradeTest(test, testResponse, testAnswers)
// console.log(test)