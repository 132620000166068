import "./SectionOne.css"
export default function SectionOne() {
    return (
        <div id="section-one">
            This section is for description/promotion/pricing (free for beta)
            <p>Samples:</p>
            <ul>
                <li>https://www.hq.xyz/</li>
                <li>https://www.codiga.io/</li>
                <li>https://potion.so/</li>
                <li>https://www.pyrls.com/</li>
            </ul>
        </div>
    )
}