import axios from 'axios'

const axiosWithToken = (accessToken) => {
    return axios.create({
        baseURL: "https://cb-backend-steelix-905a72bc5b97.herokuapp.com/api/v1",
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    })
}


// Add error catching for the methods
export const getQuestionsByTopic = async ({ accessToken, topic, uid }) => {
    const axiosInstance = axiosWithToken(accessToken)
    const response = await axiosInstance.get("/test", {
        params: {
            topic, uid
        }
    })
    return response.data
}

export const getAnswersForTest = async ({ accessToken, tsid, uid }) => {
    const axiosInstance = axiosWithToken(accessToken)
    const requestBody = {
        tsid
    }
    const response = await axiosInstance.post("/test/answer", requestBody)
    return response.data
}

