import "./Footer.css"
export default function Footer() {




    return (
        <div id="footer">
            Footer is here for social media and what not
        </div>
    )
}