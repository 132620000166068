
import Herobanner from "./Herobanner"
import SectionOne from "./SectionOne"

export default function LandingPage() {

    return (
        <div id="main-page">
            <Herobanner />
            <SectionOne />
            <SectionOne />
            <SectionOne />
        </div>
    )
}