import StartTest from "./StartTest"
import DoTest from "./DoTest"
import EndTest from "./EndTest"
import "./TestApp.css"

// States
import { useSelector } from "react-redux"

const switchPhase = (param) => {
    switch(param) {
        case 0:
            return <StartTest/>
        case 1:
            return <DoTest/>
        case 2:
            return <EndTest/>
        default:
            return <StartTest/>
        
    }
}

export default function TestApp() {
    const testAppState = useSelector((state) => state.testApp)
    
    return (
        <div id="testApp">
            {
                switchPhase(testAppState.phase)
            }
        </div>
    )
}