import { createSlice } from "@reduxjs/toolkit"
import { getQuestionsForTest } from "./testAppSlice"

const initialState = {
    isLoading: false
}

const loadingModalSlice = createSlice({
    name: 'loadingModal',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.isLoading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionsForTest.pending, (state) => {
                state.isLoading = true
                console.log('questions is loading')
            })
            .addCase(getQuestionsForTest.fulfilled, (state, action) => {
                state.isLoading = false
                console.log('questions is fullfilled')
            })
            .addCase(getQuestionsForTest.rejected, (state) => {
                state.isLoading = false
                console.log('questions is rejected')
            })
    }
})

export const { setLoading } = loadingModalSlice.actions
export default loadingModalSlice.reducer