import "./SignupPage.css"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { auth } from "../../services/firebase"
// firebase ui
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

export default function SignupPage() {

    const navigate = useNavigate()

    const uiConfig = {
        callbacks: {
            signInSuccessWithAuthResult: function (authResult) {
                // Action if the user is authenticated successfully
                navigate("/user")
            },
            uiShown: function () {
                // This is what should happen when the form is full loaded. In this example, I hide the loader element.
                document.getElementById('loader').style.display = 'none';
            }
        },
        signInFlow: 'popup',
        signInOptions: [ // This array contains all the ways an user can authenticate in your application.
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: true,
                // disableSignUp: {
                //     status: true
                // }
            },
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                requireDisplayName: true
            }
        ],
        tosUrl: 'https://www.example.com/terms-conditions', // URL to you terms and conditions.
        privacyPolicyUrl: function () { // URL to your privacy policy
            window.location.assign('https://www.example.com/privacy-policy');
        }
    }
    useEffect(() => {
        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth)

        ui.start("#firebaseui-auth-container", uiConfig)
    })

    return (

        <div id="signup-page">
            <h1 className="text-center title">Sign In Page</h1>
            <div id="firebaseui-auth-container"></div>
            <div id="loader" className="text-center">Loading form</div>

        </div>
    )
}