
import "./DoTest.css"
import { useDispatch, useSelector } from "react-redux"
import { gradeQuestionsForTest } from "../../redux/testAppSlice"
import { Button } from "react-bootstrap";
import { renderQuestions } from "../../services/utilityFunctions";



export default function DoTest() {

    const dispatch = useDispatch()
    const loadedQuestions = useSelector((state) => state.testApp.questions)
    const accessToken = useSelector((state) => state.auth.token)
    const tsid = useSelector((state) => state.testApp.tsid)
    const uid = useSelector((state) => state.auth.uid)

    return (
        <div id="do-test-page">
            <h1>I am doing test</h1>
            {
                renderQuestions(loadedQuestions)
            }
            <div className="text-center">
                <Button onClick={() => { dispatch(gradeQuestionsForTest({ accessToken, tsid, uid })) }}>Submit</Button>
            </div>
        </div>
    )
}