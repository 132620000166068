import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
export default function QuestionTypeOneGraded({ qns, idx }) {
    const userResponse = useSelector((state) => state.testApp.testResponse)
    const options = qns.options.map((opt, idx) => <Form.Check key={idx} type='radio' name={qns.qid} label={opt} checked={userResponse[qns.qid] === opt} readOnly />)

    return (
        <div style={{
            border: '1px solid black',
            padding: '5px'
        }}>
            {idx + 1 + ". "}
            {
                qns.prompt
            }
            <br />
            <Form>
                {
                    options
                }
            </Form>
            {
                "No answers provided"
            }
        </div>
    )
}