import "./Herobanner.css"
import Button from "react-bootstrap/Button"
import { useNavigate } from "react-router-dom";
function Herobanner() {
    const navigate = useNavigate()
    return (
        <div id="hero-banner">
            Hero banner here
            <ul>
                <li>Hero Image</li>
                <li>Sign up/get started button</li>
            </ul>
            <Button variant="primary" onClick={() => navigate("/user")}>Beta Test</Button>
        </div>
    )
}

export default Herobanner;