import Col from "react-bootstrap/Col";
import "./Topic.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTopic } from "../redux/testAppSlice"

// A function that returns a random hexadecimal color code
function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function Topic({ topic }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <Col xs={12} sm={6} className="topic-card" onClick={() => {
            dispatch(setTopic(topic))
            navigate("/test")
        }}>
            <div className="topic-div" style={{ backgroundColor: getRandomColor() }} >
                {topic}
            </div>
        </Col>

    )
}