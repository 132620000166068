// Testing page with all the questions
import "./EndTest.css"
import { useDispatch, useSelector } from "react-redux"
import { restartTest, resetState } from "../../redux/testAppSlice"
import { useNavigate } from "react-router-dom"
import { renderGradedQuestions } from "../../services/utilityFunctions"


export default function EndTest() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const questions = useSelector((state) => state.testApp.questions)
    const numOfCorrectQns = useSelector((state) => state.testApp.numberOfCorrectQuestions)

    return (
        <div id="end-test-page">
            <h1>Test have ended</h1>
            <h3>Result: {numOfCorrectQns} / {questions.length}</h3>
            {
                renderGradedQuestions(questions)
            }
            <button onClick={() => dispatch(restartTest())}>Retry</button>
            <button onClick={() => {
                dispatch(resetState())
                navigate("/user")
            }}>Back to Topics</button>
        </div>
    )
}