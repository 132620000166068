import { useSelector } from "react-redux"
import "./LoadingModal.css"



export function LoadingModal() {
    const isLoading = useSelector((state) => state.loadingState.isLoading)

    const renderModal = () => {
        return (
            isLoading ?
                <div id="loading-modal">
                    Loading...
                </div >
                : null)

    }

    return renderModal()

}